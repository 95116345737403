import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTableHead = createTrans({
  active: <Trans i18nKey="table.head.active" defaults="Active" />,
  email: <Trans i18nKey="table.head.email" defaults="Email" />,
  language: <Trans i18nKey="table.head.language" defaults="Language" />,
  medium: <Trans i18nKey="table.head.medium" defaults="Medium" />,
  name: <Trans i18nKey="table.head.name" defaults="Name" />,
  owner: <Trans i18nKey="table.head.owner" defaults="Owner" />,
  priority: <Trans i18nKey="table.head.priority" defaults="Priority" />,
  pushNotification: (
    <Trans i18nKey="table.head.pushNotification" defaults="Push notification" />
  ),
  sms: <Trans i18nKey="table.head.sms" defaults="SMS" />,
  type: <Trans i18nKey="table.head.type" defaults="Type" />,
  useEvenWhenHigherPriorityExists: (
    <Trans
      i18nKey="table.head.useEvenWhenHigherPriorityExists"
      defaults="Use even if higher priority channel is used"
    />
  ),
});
