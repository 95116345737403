import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { TabPanel, Tabs } from '@fleet/shared';
import { notificationTemplateSelector } from 'features/notificationTemplate/notificationTemplateSelectors';
import { useSelector } from 'store/utils';
import { LanguageTab } from 'routes/notificationTemplates/notificationTemplateDetails/languageTabs/LanguageTab';
import { TransField } from 'i18n/trans/field';
import { Stack, Typography } from '@mui/material';

const useStyles = makeStyles(
  () => ({
    root: {
      padding: '0px 24px 24px 24px',
    },
  }),
  {
    name: 'LanguageTabs',
  }
);

interface LanguageTabsProps {}

export const LanguageTabs: FC<LanguageTabsProps> = () => {
  const classes = useStyles();
  const notificationTemplate = useSelector(notificationTemplateSelector);

  if (!notificationTemplate) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Tabs>
        {notificationTemplate.notificationsByLanguage.map(
          (notificationLanguage, tabIndex) => {
            const shouldShowEmptyString =
              !notificationLanguage.emailContent &&
              !notificationLanguage.pushNotificationContent &&
              !notificationLanguage.smsContent;

            return (
              <TabPanel
                sx={{ padding: 0 }}
                key={notificationLanguage.language.id}
                label={
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={0.5}
                    data-testid={'tab-panel-label'}
                  >
                    <div>{notificationLanguage.language.name}</div>
                    {notificationLanguage.language.id ===
                      notificationTemplate.fallbackLanguage.id && (
                      <Typography variant="body1">
                        ({<TransField i18nKey="fallback" />})
                      </Typography>
                    )}
                    {shouldShowEmptyString && (
                      <Typography variant="body1">
                        ({<TransField i18nKey="empty" />})
                      </Typography>
                    )}
                  </Stack>
                }
                value={notificationLanguage.language.id}
              >
                <LanguageTab tabIndex={tabIndex} />
              </TabPanel>
            );
          }
        )}
      </Tabs>
    </div>
  );
};
