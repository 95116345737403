import { NotificationByLanguageResponse } from 'dto/notificationTemplate';

export const hasNotificationContent = (
  notificationLanguage: NotificationByLanguageResponse
) => {
  return Boolean(
    notificationLanguage.emailContent ||
      notificationLanguage.smsContent ||
      notificationLanguage.pushNotificationContent
  );
};
