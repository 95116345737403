import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import MuiTabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useDispatch, useSelector } from 'store/utils';
import { notificationTemplateSelector } from 'features/notificationTemplate/notificationTemplateSelectors';
import { Button, FormControl, Icon, Modal, useModal } from '@fleet/shared';
import { CustomTabPanel } from 'components/CustomTabPanel';
import { EmailValidationSection } from 'routes/notificationTemplates/notificationTemplateDetails/validationModal/EmailValidationSection';
import { SmsValidationSection } from 'routes/notificationTemplates/notificationTemplateDetails/validationModal/SmsValidationSection';
import { PushNotificationValidationSection } from 'routes/notificationTemplates/notificationTemplateDetails/validationModal/PushNotificationValidationSection';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransBody } from 'i18n/trans/body';
import { TransButton } from 'i18n/trans/button';
import { validateNotificationTemplates } from 'features/notificationTemplate/notificationTemplateActions';
import {
  NotificationTemplate,
  NotificationTemplatePayload,
} from 'dto/notificationTemplate';

interface NotificationTemplateValidationModalProps {
  notificationTemplatePayload: NotificationTemplatePayload;
}

export const NotificationTemplateValidationModal: FC<NotificationTemplateValidationModalProps> =
  ({ notificationTemplatePayload }) => {
    const dispatch = useDispatch();
    const { open: isOpen, onOpen, onClose } = useModal();
    const notificationTemplate = useSelector(notificationTemplateSelector);
    const [value, setValue] = useState(
      notificationTemplate?.notificationsByLanguage[0].language.id || ''
    );
    const handleChange = (event: SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };
    const [validatedResponse, setValidatedResponse] =
      useState<
        Pick<NotificationTemplate, 'type' | 'notificationsByLanguage'>
      >();

    const handleOnValidate = useCallback(async () => {
      const validateResponse = await dispatch(
        validateNotificationTemplates(notificationTemplatePayload)
      ).unwrap();
      setValidatedResponse(validateResponse);
      onOpen();
    }, [dispatch, notificationTemplatePayload, onOpen]);

    const isAnyInvalid = useMemo(() => {
      return validatedResponse?.notificationsByLanguage.find(
        (notification) =>
          notification.pushNotificationContentError ||
          notification.smsContentError ||
          notification.emailContentError
      );
    }, [validatedResponse]);

    return (
      <>
        <FormControl label="&nbsp;">
          <Button
            startIcon={<Icon name={'search'} />}
            disabled={!notificationTemplate?.id}
            onClick={handleOnValidate}
            variant="outlined"
            icon="search"
            sx={{ ml: 2 }}
          >
            <TransButton i18nKey="validate" />
          </Button>
        </FormControl>
        <Modal
          onClose={onClose}
          open={isOpen}
          title={
            <Stack direction="row" alignItems="center" spacing={1}>
              {isAnyInvalid ? (
                <Icon name="error-circle" color="error" />
              ) : (
                <Icon name="check-circle" color="success" />
              )}
              <div>
                <TransSubtitle i18nKey="templateValidation" />
              </div>
              {isAnyInvalid ? (
                <Typography variant="body2" color="error">
                  (<TransBody i18nKey="error" />)
                </Typography>
              ) : (
                <Typography variant="body2" color="success">
                  (<TransBody i18nKey="success" />)
                </Typography>
              )}
            </Stack>
          }
        >
          <>
            <MuiTabs
              sx={{ width: '720px' }}
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              {validatedResponse?.notificationsByLanguage.map(
                (notificationLanguage) => {
                  const shouldShowErrorColor =
                    notificationLanguage.emailContentError ||
                    notificationLanguage.smsContentError ||
                    notificationLanguage.pushNotificationContentError;

                  return (
                    <Tab
                      key={notificationLanguage.language.id}
                      label={
                        <Stack direction="row">
                          <div>{notificationLanguage.language.name}</div>
                          <Box
                            sx={{
                              width: '5px',
                              height: '5px',
                              borderRadius: '10px',
                              backgroundColor: Boolean(shouldShowErrorColor)
                                ? '#DD0005'
                                : 'green',
                            }}
                          />
                        </Stack>
                      }
                      value={notificationLanguage.language.id}
                    />
                  );
                }
              )}
            </MuiTabs>
            <Divider />
            {validatedResponse?.notificationsByLanguage.map(
              (notificationLanguage) => (
                <CustomTabPanel
                  key={notificationLanguage.language.id}
                  languageId={notificationLanguage.language.id}
                  value={value}
                >
                  <EmailValidationSection
                    notificationLanguage={notificationLanguage}
                  />
                  <SmsValidationSection
                    notificationLanguage={notificationLanguage}
                  />
                  <PushNotificationValidationSection
                    notificationLanguage={notificationLanguage}
                  />
                </CustomTabPanel>
              )
            )}
          </>
        </Modal>
      </>
    );
  };
