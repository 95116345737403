import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransBody = createTrans({
  content: <Trans i18nKey="body.content" defaults="Content" />,
  deleteLanguageModalBody: (
    <Trans
      i18nKey="body.deleteLanguageModalBody"
      defaults='Template in language "{{language}}" contains information. Are you sure you want to delete it?'
    />
  ),
  emailValidationContent: (
    <Trans
      i18nKey="body.emailValidationContent"
      defaults="Content(text/plain)"
    />
  ),
  emailValidationHtml: (
    <Trans i18nKey="body.emailValidationHtml" defaults="Content(text/html)" />
  ),
  emptyContent: <Trans i18nKey="body.emptyContent" defaults="Empty" />,
  error: <Trans i18nKey="body.error" defaults="Error" />,
  from: <Trans i18nKey="body.from" defaults="From" />,
  subject: <Trans i18nKey="body.subject" defaults="Subject" />,
  success: <Trans i18nKey="body.success" defaults="Success" />,
  title: <Trans i18nKey="body.title" defaults="Title" />,
});
