import type { FC } from 'react';
import React from 'react';
import { Stack, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { NotificationByLanguageResponse } from 'dto/notificationTemplate';
import { TransBody } from 'i18n/trans/body';
import NotificationContentStatus from 'components/NotificationContentStatus';
import { hasNotificationContent } from 'helpers/notificationTemplate/hasNotificationContent';

interface PushNotificationValidationSectionProps {
  notificationLanguage: NotificationByLanguageResponse;
}

export const PushNotificationValidationSection: FC<PushNotificationValidationSectionProps> =
  ({ notificationLanguage }) => {
    return (
      <>
        <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
          <Typography variant="subtitle">
            <TransSubtitle i18nKey="pushNotificationTemplate" />:
          </Typography>
          <NotificationContentStatus
            content={notificationLanguage.pushNotificationContent}
            contentErrorText={notificationLanguage.pushNotificationContentError}
            hasAnyContent={hasNotificationContent(notificationLanguage)}
          />
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
          <Typography variant="body1" fontWeight="bold">
            <TransBody i18nKey="title" />:
          </Typography>
          <Typography>{notificationLanguage.pushNotificationTitle}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body1" fontWeight="bold">
            <TransBody i18nKey="content" />:
          </Typography>
          <Typography>
            {notificationLanguage.pushNotificationContent}
          </Typography>
        </Stack>
      </>
    );
  };
