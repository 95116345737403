import React, { FC } from 'react';
import { SuccessWithIcon } from 'components/SuccessWithIcon';
import { WarningWithIcon } from 'components/WarningWithIcon';
import { ErrorWithIcon } from 'components/ErrorWithIcon';

interface NotificationContentStatusProps {
  contentErrorText: string | null;
  content: string | null;
  hasAnyContent: boolean;
}

const NotificationContentStatus: FC<NotificationContentStatusProps> = ({
  contentErrorText,
  content,
  hasAnyContent,
}) => {
  if (contentErrorText !== null) {
    return <ErrorWithIcon />;
  } else if (content !== null) {
    return <SuccessWithIcon />;
  } else if (hasAnyContent) {
    return <WarningWithIcon />;
  } else {
    return null;
  }
};

export default NotificationContentStatus;
