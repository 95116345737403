import { Classifier } from '@fleet/shared/dto/classifier';
import { BusinessEntityType } from '@fleet/shared';

export const ALLOWED_BUSINESS_ENTITY_ROLES = [
  BusinessEntityType.RETAILER,
  BusinessEntityType.CARRIER,
];

export interface ClassificationGroupItem {
  id: ClassificationKey;
  classifications: Array<Classifier>;
}

export enum ClassificationGroup {
  CULTURE = 'CULTURE',
  CUSTOMER_LEVEL = 'CUSTOMER_LEVEL',
  NOTIFICATION_TEMPLATE_TYPE = 'NOTIFICATION_TEMPLATE_TYPE',

  // !ClassificationGroups
  BUSINESS_ENTITY = 'BUSINESS_ENTITY',
  COUNTRY = 'COUNTRY',
}

export const STANDARD_CLASSIFICATION_GROUPS = [
  ClassificationGroup.CULTURE,
  ClassificationGroup.NOTIFICATION_TEMPLATE_TYPE,
  ClassificationGroup.CUSTOMER_LEVEL,
] as const;

export type ClassificationKey = Extract<
  ClassificationGroup,
  typeof STANDARD_CLASSIFICATION_GROUPS[keyof typeof STANDARD_CLASSIFICATION_GROUPS]
>;
