import { createAsyncThunk } from 'store/utils';
import { Pagination } from '@fleet/shared/dto/pagination';
import qs from 'qs';
import {
  NotificationTemplate,
  NotificationTemplateForTable,
  NotificationTemplatePayload,
  NotificationTemplateSearchFilter,
} from 'dto/notificationTemplate';
import { createAction } from '@reduxjs/toolkit';
import { api } from '@fleet/shared';

export const setNotificationTemplatesFilter = createAction<
  Partial<NotificationTemplateSearchFilter>
>('notificationTemplates/setNotificationTemplatesFilter');

export const setNotificationTemplate = createAction<
  NotificationTemplate | undefined
>('notificationTemplates/setNotificationTemplate');

export const clearNotificationTemplates = createAction(
  'notificationTemplates/clearNotificationTemplates'
);

export const getNotificationTemplates = createAsyncThunk<
  Pagination<NotificationTemplateForTable>,
  Partial<NotificationTemplateSearchFilter>
>('notificationTemplates/getAll', async (filter, { dispatch }) => {
  dispatch(setNotificationTemplatesFilter(filter));

  return (
    await api.get(
      `/notification-templates${qs.stringify(filter, {
        addQueryPrefix: true,
        skipNulls: true,
      })}`
    )
  ).data;
});

export const getNotificationTemplateById = createAsyncThunk<
  NotificationTemplate,
  string | number
>('notificationTemplates/getNotificationTemplateById', async (id) => {
  return (await api.get(`/notification-templates/${id}`)).data;
});

export const updateOrCreateNotificationTemplate = createAsyncThunk<
  NotificationTemplate,
  NotificationTemplatePayload
>(
  'notificationTemplates/updateOrCreateNotificationTemplate',
  async ({ id, ...rest }) => {
    return (
      await (id ? api.put : api.post)(
        `/notification-templates${id ? `/${id}` : ''}`,
        rest
      )
    ).data;
  }
);

export const validateNotificationTemplates = createAsyncThunk<
  Pick<NotificationTemplate, 'notificationsByLanguage' | 'type'>,
  NotificationTemplatePayload
>(
  'notificationTemplates/validateNotificationTemplates',
  async ({ notificationsByLanguage, typeId }) => {
    return (
      await api.post('/notification-templates/validate', {
        notificationsByLanguage,
        typeId,
      })
    ).data;
  }
);

export const deleteNotificationTemplateById = createAsyncThunk<
  void,
  string | number
>('notificationTemplates/deleteNotificationTemplateById', async (id) => {
  return (await api.delete(`/notification-templates/${id}`)).data;
});

export const deleteNotificationTemplates = createAsyncThunk<
  void,
  Array<string>
>('notificationTemplates/deleteNotificationTemplates', async (payload) => {
  return await api.delete(`/notification-templates/bulk`, { data: payload });
});
