import { NotificationChannelColumn } from 'dto/notificationTemplate';

export const initialNotificationChannelData: Array<NotificationChannelColumn> =
  [
    {
      orderNumber: 1,
      isActive: true,
      channel: {
        id: 'NOTIFICATION_CHANNEL.EMAIL',
        name: 'Email',
      },
      sendEvenWhenHigherPriorityExists: false,
    },
    {
      orderNumber: 2,
      isActive: false,
      channel: {
        id: 'NOTIFICATION_CHANNEL.SMS',
        name: 'SMS',
      },
      sendEvenWhenHigherPriorityExists: false,
    },
    {
      orderNumber: 3,
      isActive: false,
      channel: {
        id: 'NOTIFICATION_CHANNEL.PUSH_NOTIFICATION',
        name: 'Push notification',
      },
      sendEvenWhenHigherPriorityExists: false,
    },
  ];
