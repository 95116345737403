import { FC, useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'store/utils';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import {
  ALLOWED_BUSINESS_ENTITY_ROLES,
  ClassificationGroup,
} from 'dto/classification';
import { NotificationTemplatePayload } from 'dto/notificationTemplate';
import {
  getNotificationTemplateById,
  getNotificationTemplates,
  setNotificationTemplate,
  updateOrCreateNotificationTemplate,
} from 'features/notificationTemplate/notificationTemplateActions';
import {
  Button,
  DateField,
  FormControl,
  FormProvider,
  formSubmit,
  PeriodField,
  PeriodGridWrapper,
  SelectField,
  SelectOwnerField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Grid, Stack, Typography } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { NotificationLanguageModalArrayField } from 'routes/notificationTemplates/notificationTemplateDetails/NotificationLanguageModalArrayField';
import { NotificationChannelsTable } from 'routes/notificationTemplates/notificationTemplateDetails/NotificationChannelsTable';
import {
  formatNotificationTemplatePayload,
  mapNotificationTableToPayload,
} from 'helpers/notificationTemplate/mapper';
import { initialNotificationChannelData } from 'helpers/notificationTemplate/consts';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  notificationTemplateSelector,
  notificationTemplatesFilterSelector,
} from 'features/notificationTemplate/notificationTemplateSelectors';
import { NotificationTemplateDetailsToolbar } from 'routes/notificationTemplates/notificationTemplateDetails/NotificationTemplateDetailsToolbar';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { NotificationTemplateValidationModal } from 'routes/notificationTemplates/notificationTemplateDetails/validationModal/NotificationTemplateValidationModal';
import { useNotificationTemplatePayload } from 'hooks/useNotificationTemplatePayload';
import { useComponentWillUnmount } from 'hooks/useComponentWillUnmount';
import { currentLocaleConfiguration } from '@fleet/shared/i18n';
import { dtid } from 'helpers/data-test-ids';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';

const useStyles = makeStyles(
  () => ({
    searchContent: {
      padding: '8px 16px',
    },
  }),
  {
    name: 'NotificationTemplateDetailsForm',
  }
);

interface NotificationTemplateDetailsFormProps {}

export const NotificationTemplateDetailsForm: FC<NotificationTemplateDetailsFormProps> =
  () => {
    const classes = useStyles();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const dispatch = useDispatch();
    const businessEntities = useSelector(businessEntitiesSelector);
    const languages = useClassificationOptions(ClassificationGroup.CULTURE);
    const customerLevels = useClassificationOptions(
      ClassificationGroup.CUSTOMER_LEVEL
    );
    const notificationTypes = useClassificationOptions(
      ClassificationGroup.NOTIFICATION_TEMPLATE_TYPE
    );
    const notificationTemplate = useSelector(notificationTemplateSelector);
    const notificationTemplatesFilter = useSelector(
      notificationTemplatesFilterSelector
    );

    const alert = useAlert();

    useEffect(() => {
      if (id && !notificationTemplate?.id) {
        dispatch(getNotificationTemplateById(id));
      }
    }, [dispatch, id, notificationTemplate?.id]);

    useComponentWillUnmount(() => {
      dispatch(setNotificationTemplate(undefined));
    });

    const onSubmit = (
      notificationTemplatePayload: NotificationTemplatePayload
    ) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();

        const payload = formatNotificationTemplatePayload(
          notificationTemplatePayload
        );

        // This is to set the initial channel priority list if customer leaves it unchanged.
        if (!payload.notificationChannels) {
          payload.notificationChannels = mapNotificationTableToPayload(
            initialNotificationChannelData
          );
        }

        if (payload.notificationChannels.find((channel) => channel.isActive)) {
          payload.isActive = true;
        }

        const notificationTemplate = await dispatch(
          updateOrCreateNotificationTemplate(payload)
        ).unwrap();

        dispatch(setNotificationTemplate(notificationTemplate));
        dispatch(
          getNotificationTemplates({
            ...notificationTemplatesFilter,
            organizationId: notificationTemplate.organization.id,
          })
        );

        alert.success(
          <TransAlert i18nKey={id ? 'templateUpdated' : 'templateCreated'} />
        );

        if (!id && notificationTemplate.id) {
          history.push(
            `/notification-templates/edit/${notificationTemplate.id}`
          );
        }
      });

    const { initialValues, notificationTemplatePayload } =
      useNotificationTemplatePayload(notificationTemplate);

    const { form, handleSubmit, values } = useForm<NotificationTemplatePayload>(
      {
        initialValues,
        onSubmit,
        subscription: { values: true },
      }
    );

    const fallbackLanguageOptions = useMemo(
      () =>
        languages.filter((lang) =>
          values.notificationsByLanguage?.some(
            (notification) => notification.languageId === lang.value
          )
        ),
      [languages, values.notificationsByLanguage]
    );

    const handleReset = useCallback(() => {
      form.reset();
    }, [form]);

    return (
      <div className={classes.searchContent}>
        <Grid
          container
          columns={2}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Grid item>
            <Typography variant="subtitle" fontWeight="700">
              <TransSubtitle i18nKey="details" />
            </Typography>
          </Grid>
          <NotificationTemplateDetailsToolbar />
        </Grid>

        <FormProvider form={form}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              sx={{ alignItems: 'flex-start' }}
              spacing={2}
              columns={5}
            >
              <Grid item xs={1}>
                <SelectOwnerField
                  businessEntities={businessEntities}
                  allowedBusinessEntityTypes={ALLOWED_BUSINESS_ENTITY_ROLES}
                  name="organizationId"
                  disabled={!!notificationTemplate?.id}
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="name"
                  label={<TransField i18nKey="name" />}
                  required
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="typeId"
                  required
                  label={<TransField i18nKey="type" />}
                  options={notificationTypes}
                />
              </Grid>
              <Grid item xs={1}>
                <NotificationLanguageModalArrayField />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="fallbackLanguageId"
                  required
                  label={<TransField i18nKey="fallbackLanguage" />}
                  options={fallbackLanguageOptions}
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="preferredCustomerLevelId"
                  required
                  label={<TransField i18nKey="preferredCustomerLevel" />}
                  options={customerLevels}
                />
              </Grid>
              <PeriodField
                from={{
                  name: 'validity.from',
                  label: <TransField i18nKey="validFrom" />,
                  required: true,
                }}
                to={{
                  name: 'validity.to',
                  label: <TransField i18nKey="validTo" />,
                  isClearable: true,
                }}
              />
              <PeriodGridWrapper
                componentFrom={
                  <DateField
                    name="blackoutTimeFrom"
                    label={<TransField i18nKey="blackoutTimeFrom" />}
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat={currentLocaleConfiguration.timeFormat}
                    timeFormat={currentLocaleConfiguration.timeFormat}
                    timeIntervals={1}
                    isClearable
                  />
                }
                componentTo={
                  <DateField
                    name="blackoutTimeTo"
                    label={<TransField i18nKey="blackoutTimeTo" />}
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat={currentLocaleConfiguration.timeFormat}
                    timeFormat={currentLocaleConfiguration.timeFormat}
                    timeIntervals={1}
                    isClearable
                  />
                }
              />
              <Grid item xs={12} sx={{ marginTop: '16px' }}>
                <NotificationChannelsTable />
              </Grid>
              <Grid item xs="auto" sx={{ ml: 'auto' }}>
                <Stack direction="row" spacing={1}>
                  <FormControl label="&nbsp;">
                    <Button
                      sx={{ whiteSpace: 'nowrap' }}
                      variant="text"
                      onClick={handleReset}
                    >
                      <TransButton i18nKey="resetFields" />
                    </Button>
                  </FormControl>
                  <FormControl label="&nbsp;">
                    <Button
                      variant="contained"
                      type="submit"
                      icon="check"
                      data-testid={dtid.templatesSave}
                    >
                      <TransButton i18nKey="save" />
                    </Button>
                  </FormControl>
                  {notificationTemplatePayload && (
                    <NotificationTemplateValidationModal
                      notificationTemplatePayload={notificationTemplatePayload}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </div>
    );
  };
