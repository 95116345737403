import React, { FC } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { Input } from '@fleet/shared';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransBody } from 'i18n/trans/body';
import { NotificationByLanguageResponse } from 'dto/notificationTemplate';
import NotificationContentStatus from 'components/NotificationContentStatus';
import { hasNotificationContent } from 'helpers/notificationTemplate/hasNotificationContent';

interface EmailValidationSectionProps {
  notificationLanguage: NotificationByLanguageResponse;
}

export const EmailValidationSection: FC<EmailValidationSectionProps> = ({
  notificationLanguage,
}) => {
  const senderData =
    notificationLanguage.emailSenderName &&
    notificationLanguage.emailSenderEmail
      ? `${notificationLanguage.emailSenderName} <${notificationLanguage.emailSenderEmail}>`
      : '';

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
        <Typography variant="subtitle">
          <TransSubtitle i18nKey="emailTemplate" />:
        </Typography>
        <NotificationContentStatus
          content={notificationLanguage.emailContent}
          contentErrorText={notificationLanguage.emailContentError}
          hasAnyContent={hasNotificationContent(notificationLanguage)}
        />
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
        <Typography variant="body1" fontWeight="bold">
          <TransBody i18nKey="from" />:
        </Typography>
        <Typography>{senderData}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body1" fontWeight="bold">
          <TransBody i18nKey="subject" />:
        </Typography>
        <Typography>{notificationLanguage.emailSubject}</Typography>
      </Stack>
      <Typography variant="body1" fontWeight="bold" sx={{ mt: 2, mb: 1 }}>
        <TransBody i18nKey="emailValidationContent" />:
      </Typography>
      <Input
        multiline
        disabled
        rows={7}
        value={notificationLanguage.emailContent ?? ''}
      />
      <Typography variant="body1" fontWeight="bold" sx={{ mt: 2, mb: 1 }}>
        <TransBody i18nKey="emailValidationHtml" />:
      </Typography>
      <iframe
        srcDoc={notificationLanguage.emailContent || ''}
        id="emailTemplatePreview"
        style={{
          marginBottom: '24px',
          width: '100%',
          height: '160px',
          border: '1px solid #E1E1E1',
          borderRadius: '4px',
        }}
      ></iframe>
      <Divider />
    </>
  );
};
