import type { FC } from 'react';
import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { TransBody } from 'i18n/trans/body';
import { Icon } from '@fleet/shared';

interface SuccessWithIconProps {}

export const SuccessWithIcon: FC<SuccessWithIconProps> = () => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Icon name="check-circle" color="success" />
      <Box sx={{ marginLeft: 1 }}>
        <Typography variant="body1" fontWeight="bold" color={'green'}>
          <TransBody i18nKey="success" />
        </Typography>
      </Box>
    </Stack>
  );
};
