import { createReducer } from '@reduxjs/toolkit';
import { Pagination } from '@fleet/shared/dto/pagination';
import {
  NotificationTemplate,
  NotificationTemplateForTable,
  NotificationTemplateSearchFilter,
} from 'dto/notificationTemplate';
import {
  clearNotificationTemplates,
  getNotificationTemplateById,
  getNotificationTemplates,
  setNotificationTemplate,
  setNotificationTemplatesFilter,
} from 'features/notificationTemplate/notificationTemplateActions';

interface NotificationTemplateReducer {
  filter: Partial<NotificationTemplateSearchFilter>;
  list?: Pagination<NotificationTemplateForTable>;
  current?: NotificationTemplate;
}

const initialState: NotificationTemplateReducer = {
  filter: {},
};

export const notificationTemplatesReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(
        setNotificationTemplate,
        (state, { payload: notificationTemplate }) => {
          state.current = notificationTemplate;
          if (!notificationTemplate) {
            state.current = undefined;
          }
        }
      )
      .addCase(clearNotificationTemplates, (state) => {
        state.list = undefined;
      })
      .addCase(getNotificationTemplates.rejected, (state) => {
        state.list = undefined;
      })
      .addCase(getNotificationTemplates.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(getNotificationTemplateById.rejected, (state) => {
        state.current = undefined;
      })
      .addCase(getNotificationTemplateById.fulfilled, (state, action) => {
        state.current = action.payload;
      })
      .addCase(setNotificationTemplatesFilter, (state, action) => {
        state.filter = action.payload;
      });
  }
);
