import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Header } from 'components/Header';
import { useDispatch, useSelector } from 'store/utils';
import {
  getBusinessEntities,
  getClassifications,
} from 'features/classification/classificationActions';
import { appLoadingSelector } from 'features/loading/loadingSelectors';
import {
  AppVersion,
  AuthWrapper,
  EnvironmentBanner,
  useAuth,
} from '@fleet/shared';
import { NotificationTemplates } from 'routes/notificationTemplates/NotificationTemplates';
import { NotificationTemplateDetails } from 'routes/notificationTemplates/notificationTemplateDetails/NotificationTemplateDetails';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';

export default function App() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const loading = useSelector(appLoadingSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(getBusinessEntities());
    }
  }, [auth.isAuthenticated, dispatch]);

  useEffect(() => {
    if (auth.isAuthenticated && currentBusinessEntityId) {
      dispatch(getClassifications());
    }
  }, [auth.isAuthenticated, dispatch, currentBusinessEntityId]);

  return (
    <>
      <Router>
        <AuthWrapper appLoading={loading}>
          <EnvironmentBanner envName={process.env.REACT_APP_ENVIRONMENT!} />
          <Header />
          <Switch>
            <Route
              exact
              path="/notification-templates"
              component={NotificationTemplates}
            />
            <Route
              exact
              path="/notification-templates/:action(create|edit)?/:id?"
              component={NotificationTemplateDetails}
            />
          </Switch>
        </AuthWrapper>
      </Router>
      <AppVersion version={process.env.REACT_APP_VERSION!} />
    </>
  );
}
