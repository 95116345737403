import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransNav = createTrans({
  notificationTemplates: (
    <Trans
      i18nKey="nav.notifications.label"
      defaults="Notification templates"
    />
  ),
});
