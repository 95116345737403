import { createSelector, selector } from 'store/utils';

export const selectNotificationTemplatesFilter = selector(
  (state) => state.notificationTemplate.filter
);
export const notificationTemplatesFilterSelector = createSelector(
  selectNotificationTemplatesFilter
);

export const selectNotificationTemplates = selector(
  (state) => state.notificationTemplate.list
);
export const notificationTemplatesSelector = createSelector(
  selectNotificationTemplates
);

export const selectNotificationTemplate = selector(
  (state) => state.notificationTemplate.current
);
export const notificationTemplateSelector = createSelector(
  selectNotificationTemplate
);
