import { Box } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  languageId: string;
  value: string;
}

export const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, languageId } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== languageId}
      id={`language-tabpanel-${languageId}`}
    >
      {value === languageId && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
};
