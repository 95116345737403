import { createLoadingSelector } from 'store/utils';
import {
  getBusinessEntities,
  getClassifications,
} from 'features/classification/classificationActions';
import {
  deleteNotificationTemplateById,
  deleteNotificationTemplates,
  getNotificationTemplateById,
  getNotificationTemplates,
  updateOrCreateNotificationTemplate,
} from 'features/notificationTemplate/notificationTemplateActions';

export const appLoadingSelector = createLoadingSelector(
  getClassifications,
  getBusinessEntities
);
export const notificationTemplatesTableLoadingSelector = createLoadingSelector(
  getNotificationTemplates,
  deleteNotificationTemplates
);
export const notificationTemplatesDetailsLoadingSelector =
  createLoadingSelector(
    getNotificationTemplateById,
    updateOrCreateNotificationTemplate,
    deleteNotificationTemplateById
  );
