import { store, RootState } from 'store';
import {
  AsyncThunkPayloadCreator,
  createAsyncThunk as createAsyncThunkNative,
  createSelector as createSelectorNative,
  Selector,
  SerializedError,
} from '@reduxjs/toolkit';

import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';

type AppDispatch = typeof store.dispatch;
export const useDispatch = () => useReduxDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export enum AsyncActionStatus {
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

export const isAsyncAction = (status: AsyncActionStatus) =>
  [
    AsyncActionStatus.PENDING,
    AsyncActionStatus.FULFILLED,
    AsyncActionStatus.REJECTED,
  ].includes(status);

export const createAsyncThunk = <
  Returned = () => Promise<void>,
  ThunkArg = void
>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<
    Returned,
    ThunkArg,
    {
      state: RootState;
    }
  >
) =>
  createAsyncThunkNative<
    Returned,
    ThunkArg,
    {
      state: RootState;
    }
  >(typePrefix, payloadCreator, {
    serializeError: (error) => error as unknown as SerializedError,
  });

export const selector = <R>(selector: Selector<RootState, R>) => selector;

export const createSelector = <R>(
  selector: Selector<RootState, R>,
  combiner: (res: R) => R = (result) => result
) => createSelectorNative(selector, combiner);

export type LoadingActions = Array<{ typePrefix: string }>;
export const createLoadingSelector =
  (...actions: LoadingActions) =>
  (state: RootState) =>
    actions
      .map((action) => state.loading?.[action.typePrefix])
      .some((loading) => loading);
