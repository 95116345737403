export const dtid = {
  templatesAdd: 'notification-templates-add',
  templateDetails: 'notification-template-details-layout',
  templatesSave: 'notification-templates-save',
  templatesSearch: 'notification-templates-search',
  templatesSearchResults: 'notification-templates-search-results-table',
  templatesDeleteSelected:
    'notification-templates-search-results-delete-button',
  templatesLanguageTabSubmit: 'language-tab-submit',
};
