import { useMemo } from 'react';
import { mapNotificationTemplateResponseToPayload } from 'helpers/notificationTemplate/mapper';
import {
  NotificationTemplate,
  NotificationTemplatePayload,
} from 'dto/notificationTemplate';
import { useSelector } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';

type UseNotificationTemplatePayloadReturn = {
  notificationTemplatePayload: NotificationTemplatePayload | undefined;
  initialValues: Partial<NotificationTemplatePayload>;
};

export const useNotificationTemplatePayload = (
  notificationTemplate: NotificationTemplate | undefined
): UseNotificationTemplatePayloadReturn => {
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);

  const notificationTemplatePayload = useMemo(() => {
    if (notificationTemplate) {
      return mapNotificationTemplateResponseToPayload(notificationTemplate);
    }
  }, [notificationTemplate]);

  const initialValues: Partial<NotificationTemplatePayload> = useMemo(() => {
    if (!notificationTemplatePayload) {
      return { organizationId: currentBusinessEntityId };
    }

    return notificationTemplatePayload;
  }, [notificationTemplatePayload, currentBusinessEntityId]);

  return {
    notificationTemplatePayload,
    initialValues,
  };
};
