import { FC, useEffect, useState } from 'react';
import { useCallback, useMemo } from 'react';
import { useSortBy, useTable } from 'react-table';
import {
  Checkbox,
  Table,
  TableColumns,
  TableDragDropProps,
  useDraggable,
  useFormContext,
} from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/tableHead';
import { Box, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import {
  NotificationChannelColumn,
  NotificationTemplatePayload,
} from 'dto/notificationTemplate';
import { mapNotificationTableToPayload } from 'helpers/notificationTemplate/mapper';
import { initialNotificationChannelData } from 'helpers/notificationTemplate/consts';
import { notificationTemplateSelector } from 'features/notificationTemplate/notificationTemplateSelectors';
import { useSelector } from 'store/utils';

interface NotificationChannelsTableProps {}

export const NotificationChannelsTable: FC<NotificationChannelsTableProps> =
  () => {
    const notificationTemplate = useSelector(notificationTemplateSelector);
    const form = useFormContext<NotificationTemplatePayload>();
    const [localData, setLocalData] = useState(
      notificationTemplate?.notificationChannels ??
        initialNotificationChannelData
    );

    useEffect(() => {
      if (notificationTemplate?.notificationChannels) {
        setLocalData(notificationTemplate.notificationChannels);
      }
    }, [notificationTemplate]);

    const handleCheckboxChange = useCallback(
      (rowIndex: number, fieldName: string, newValue: boolean) => {
        const newData = localData.map((item, index) => {
          if (index === rowIndex) {
            return { ...item, [fieldName]: newValue };
          }
          return item;
        });
        setLocalData(newData);

        form.change(
          'notificationChannels',
          mapNotificationTableToPayload(newData)
        );
      },
      [localData, form]
    );

    const columns: TableColumns<NotificationChannelColumn> = useMemo(
      () => [
        {
          id: 'isActive',
          accessor: 'isActive',
          Header: <TransTableHead i18nKey="active" />,
          Cell: ({ row: { original, index }, column: { id } }) => (
            <Checkbox
              size="small"
              checked={original.isActive}
              onChange={(e) =>
                handleCheckboxChange(index, id, e.target.checked)
              }
            />
          ),
          width: 90,
        },
        {
          accessor: 'orderNumber',
          Header: <TransTableHead i18nKey="priority" />,
          width: 100,
        },
        {
          id: 'channel.id',
          accessor: ({ channel }) => channel?.name,
          Header: <TransTableHead i18nKey="medium" />,
          width: 100,
        },
        {
          id: 'sendEvenWhenHigherPriorityExists',
          accessor: 'sendEvenWhenHigherPriorityExists',
          Header: <TransTableHead i18nKey="useEvenWhenHigherPriorityExists" />,
          Cell: ({ row: { index, original }, column: { id } }) => {
            if (index !== 0) {
              return (
                <Checkbox
                  size="small"
                  checked={original.sendEvenWhenHigherPriorityExists}
                  onChange={(e) =>
                    handleCheckboxChange(index, id, e.target.checked)
                  }
                />
              );
            } else {
              return <></>;
            }
          },
          width: 600,
        },
      ],
      [handleCheckboxChange]
    );

    const defaultSortBy = useMemo(
      () => [
        {
          id: 'orderNumber',
          desc: false,
        },
      ],
      []
    );

    const handleDragEnd = useCallback<
      TableDragDropProps<NotificationChannelColumn>['onDragEnd']
    >(
      ({ data }) => {
        const updatedData = data.map((item, index) => {
          return { ...item, orderNumber: index + 1 };
        });

        setLocalData(updatedData);

        form.change(
          'notificationChannels',
          mapNotificationTableToPayload(updatedData)
        );
      },
      [form]
    );

    const table = useTable(
      { data: localData, columns, initialState: { sortBy: defaultSortBy } },
      useSortBy,
      useDraggable
    );

    return (
      <>
        <Typography variant="subtitle" fontWeight="700">
          <TransSubtitle i18nKey="notificationChannels" />
        </Typography>
        <Box sx={{ marginTop: '16px' }}>
          <Table table={table} draggable onDragEnd={handleDragEnd} />
        </Box>
      </>
    );
  };
