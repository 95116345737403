import { Trans } from 'react-i18next';
import { createTrans } from 'i18n/trans/index';

export const TransTitle = createTrans({
  notificationTemplate: (
    <Trans
      i18nKey="header.notificationTemplate"
      defaults="Notification template: {{name}}"
    />
  ),
  notificationTemplates: (
    <Trans
      i18nKey="header.notificationTemplates"
      defaults="Notification templates"
    />
  ),
});
