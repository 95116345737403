import React, { FC, useCallback, useMemo, useState } from 'react';
import { ModalFieldArray, SelectField } from '@fleet/shared';
import { TransField } from 'i18n/trans/field';
import { Grid } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { noop } from '@fleet/shared/utils/noop';
import { ClassificationGroup } from 'dto/classification';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { useTranslation } from 'react-i18next';
import { LanguageDeletionConfirmationModal } from 'components/LanguageDeletionConfirmationModal';
import { notificationTemplateSelector } from 'features/notificationTemplate/notificationTemplateSelectors';
import { useSelector } from 'store/utils';

type LanguageValue = { languageId: string };

interface ModalArrayValues {
  notificationsByLanguage: Array<LanguageValue>;
}

interface NotificationLanguageModalArrayField {}

export const NotificationLanguageModalArrayField: FC<NotificationLanguageModalArrayField> =
  () => {
    const languages = useClassificationOptions(ClassificationGroup.CULTURE);
    const { t } = useTranslation();
    const [removeResolve, setRemoveResolve] =
      useState<(value: boolean) => void>();
    const notificationTemplate = useSelector(notificationTemplateSelector);
    const [selectedLanguageName, setSelectedLanguageName] = useState<
      string | null
    >(null);

    const validateRow = useCallback(
      (rows?: Array<LanguageValue>) => {
        if (
          rows?.some((row) => Object.keys(row).length === 0) ||
          !rows?.length
        ) {
          return t('field.required', 'Field is required');
        }
        return null;
      },
      [t]
    );

    const validateDuplicateLanguage = useCallback(
      (selectedValue: string, allValues: ModalArrayValues) => {
        const occurrences = allValues.notificationsByLanguage?.filter(
          (lang) => lang.languageId === selectedValue
        ).length;

        if (occurrences > 1) {
          return <TransField i18nKey="languageNeedsToBeUnique" />;
        }

        return undefined;
      },
      []
    );

    const valueToLabelMap = useMemo(() => {
      return languages.reduce<Record<string, string>>((acc, curr) => {
        acc[curr.value] = curr.label;
        return acc;
      }, {});
    }, [languages]);

    const handleConfirm = useCallback(() => {
      setSelectedLanguageName(null);
      removeResolve && removeResolve(true);
    }, [removeResolve]);

    const handleBeforeRemove = useCallback(
      (index: number) => {
        const itemToRemove =
          notificationTemplate?.notificationsByLanguage[index];

        if (
          itemToRemove &&
          (itemToRemove.smsContent ||
            itemToRemove.emailContent ||
            itemToRemove.pushNotificationContent)
        ) {
          setSelectedLanguageName(itemToRemove.language.name);

          return new Promise<boolean>((resolve) => {
            setRemoveResolve(() => resolve);
          });
        }

        return Promise.resolve(true);
      },
      [notificationTemplate?.notificationsByLanguage]
    );

    const renderFields = useCallback(
      ({ name, removeButton }) => {
        return {
          canRemove: false,
          field: (
            <Grid container spacing={2}>
              <Grid item sx={{ flex: 1 }}>
                <SelectField
                  name={`${name}.languageId`}
                  validate={validateDuplicateLanguage}
                  options={languages}
                />
              </Grid>
              <Grid item>{removeButton}</Grid>
            </Grid>
          ),
        };
      },
      [validateDuplicateLanguage, languages]
    );

    return (
      <>
        <ModalFieldArray<LanguageValue>
          label={<TransField i18nKey="languages" />}
          name="notificationsByLanguage"
          validate={validateRow}
          render={renderFields}
          accessor={({ languageId }) => {
            return languageId ? valueToLabelMap[languageId] : '';
          }}
          onBeforeRemove={handleBeforeRemove}
          modalProps={{
            title: <TransField i18nKey="languages" />,
            actionButton: {
              label: <TransButton i18nKey="confirm" />,
              icon: 'check',
              onClick: noop,
            },
          }}
          required
        />
        <LanguageDeletionConfirmationModal
          isOpen={Boolean(selectedLanguageName)}
          onConfirm={handleConfirm}
          onClose={() => setSelectedLanguageName(null)}
          selectedLanguageName={selectedLanguageName}
        />
      </>
    );
  };
