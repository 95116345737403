import { formatDate } from '@fleet/shared';
import {
  NotificationChannelColumn,
  NotificationChannelColumnPayload,
  NotificationTemplate,
  NotificationTemplatePayload,
} from 'dto/notificationTemplate';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _pick from 'lodash/pick';

const formatBlackoutTime = (date: string | null) =>
  !_isEmpty(date) && !_isNil(date) ? formatDate(date, 'HH:mm') : null;

export const formatNotificationTemplatePayload = (
  notificationTemplatePayload: NotificationTemplatePayload
) => ({
  ...notificationTemplatePayload,
  blackoutTimeFrom: formatBlackoutTime(
    notificationTemplatePayload.blackoutTimeFrom
  ),
  blackoutTimeTo: formatBlackoutTime(
    notificationTemplatePayload.blackoutTimeTo
  ),
});

export const mapNotificationTableToPayload = (
  notificationTableData: Array<NotificationChannelColumn>
): Array<NotificationChannelColumnPayload> =>
  notificationTableData.map(({ channel, ...notification }) => ({
    ...notification,
    channelId: channel.id,
  }));

export const mapNotificationTemplateResponseToPayload = (
  notificationTemplate: NotificationTemplate
): NotificationTemplatePayload => ({
  ..._pick(notificationTemplate, ['id', 'name', 'isActive', 'validity']),
  fallbackLanguageId: notificationTemplate.fallbackLanguage.id,
  typeId: notificationTemplate.type?.id,
  preferredCustomerLevelId: notificationTemplate.preferredCustomerLevel?.id,
  organizationId: notificationTemplate.organization.id,
  notificationChannels: mapNotificationTableToPayload(
    notificationTemplate.notificationChannels ?? []
  ),
  notificationsByLanguage: (
    notificationTemplate.notificationsByLanguage ?? []
  ).map(({ language, smsSenderName, ...notificationContent }) => ({
    ...notificationContent,
    smsSenderName: smsSenderName ?? notificationTemplate.organization.name,
    languageId: language?.id,
  })),
  blackoutTimeFrom:
    notificationTemplate.blackoutTimeFrom &&
    `2001-01-01T${notificationTemplate.blackoutTimeFrom}:00`,
  blackoutTimeTo:
    notificationTemplate.blackoutTimeTo &&
    `2001-01-01T${notificationTemplate.blackoutTimeTo}:00`,
});
