import type { FC } from 'react';
import React, { useCallback } from 'react';
import { Grid } from '@mui/material';
import { Button, ConfirmDeleteModal, Icon, useModal } from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import {
  deleteNotificationTemplateById,
  getNotificationTemplates,
} from 'features/notificationTemplate/notificationTemplateActions';
import { useDispatch, useSelector } from 'store/utils';
import { useHistory } from 'react-router-dom';
import { TransAlert } from 'i18n/trans/alert';
import { useAlert } from 'react-alert';
import {
  notificationTemplateSelector,
  notificationTemplatesFilterSelector,
} from 'features/notificationTemplate/notificationTemplateSelectors';
import { TransModal } from 'i18n/trans/modal';

interface NotificationTemplateDetailsToolbarProps {}

export const NotificationTemplateDetailsToolbar: FC<NotificationTemplateDetailsToolbarProps> =
  () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const alert = useAlert();
    const notificationTemplate = useSelector(notificationTemplateSelector);
    const notificationTemplateFilters = useSelector(
      notificationTemplatesFilterSelector
    );
    const { open: isOpen, onOpen, onClose } = useModal();
    const onDelete = useCallback(async () => {
      if (notificationTemplate?.id) {
        await dispatch(
          deleteNotificationTemplateById(notificationTemplate.id)
        ).unwrap();
        await dispatch(getNotificationTemplates(notificationTemplateFilters));

        alert.success(
          <TransAlert i18nKey="templatesRemoved" values={{ count: 1 }} />
        );
        history.replace('/notification-templates');
      }
    }, [
      notificationTemplate?.id,
      dispatch,
      notificationTemplateFilters,
      alert,
      history,
    ]);

    if (!notificationTemplate?.id) {
      return null;
    }

    return (
      <Grid item>
        <Button
          disabled
          variant="text"
          size="small"
          startIcon={<Icon name="question" />}
        >
          <TransButton i18nKey="help" />
        </Button>
        <Button
          variant="text"
          size="small"
          startIcon={<Icon name="add" />}
          disabled
        >
          <TransButton i18nKey="createNotification" />
        </Button>
        <Button
          disabled
          variant="text"
          size="small"
          startIcon={<Icon name="duplicate" />}
        >
          <TransButton i18nKey="copy" />
        </Button>
        <Button
          onClick={onOpen}
          variant="text"
          size="small"
          color="error"
          startIcon={<Icon name="delete" />}
        >
          <TransButton i18nKey="delete" />
        </Button>
        <ConfirmDeleteModal
          handleDelete={onDelete}
          title={<TransModal i18nKey="deleteNotificationTemplate" />}
          description={
            <TransModal
              i18nKey="deleteNotificationTemplateDescription"
              values={{ count: 1 }}
            />
          }
          isOpen={isOpen}
          onClose={onClose}
        />
      </Grid>
    );
  };
