import { type FC } from 'react';
import { NotificationTemplatesSearchForm } from 'routes/notificationTemplates/NotificationTemplatesSearchForm';
import { Layout } from '@fleet/shared';
import { Divider } from '@mui/material';
import { NotificationTemplatesTable } from 'routes/notificationTemplates/NotificationTemplatesTable';

interface NotificationTemplatesProps {}

export const NotificationTemplates: FC<NotificationTemplatesProps> = () => (
  <Layout>
    <>
      <NotificationTemplatesSearchForm />
      <Divider />
      <NotificationTemplatesTable />
    </>
  </Layout>
);
