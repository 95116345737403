import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTooltip = createTrans({
  smsSenderTooltip: (
    <Trans
      i18nKey="tooltip.smsSenderTooltip"
      defaults="This is an organizational level parameter that can only be modified by Turnit support."
    />
  ),
});
