import type { FC } from 'react';
import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Icon } from '@fleet/shared';
import { TransBody } from 'i18n/trans/body';

interface ErrorWithIconProps {}

export const ErrorWithIcon: FC<ErrorWithIconProps> = () => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Icon name="error-circle" color="error" />
      <Box sx={{ marginLeft: 1 }}>
        <Typography variant="body1" fontWeight="bold" color="error">
          <TransBody i18nKey="error" />
        </Typography>
      </Box>
    </Stack>
  );
};
