import type { FC } from 'react';
import React from 'react';
import { Grid } from '@mui/material';
import { TextField } from '@fleet/shared';
import { TransField } from 'i18n/trans/field';

interface PushNotificationTemplate {
  index: number;
}

export const PushNotificationTemplate: FC<PushNotificationTemplate> = ({
  index,
}) => {
  return (
    <Grid container sx={{ alignItems: 'flex-start' }} spacing={2} columns={1}>
      <Grid item xs={1}>
        <TextField
          name={`notificationsByLanguage[${index}].pushNotificationTitle`}
          label={<TransField i18nKey="pushNotificationTitle" />}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          multiline
          rows={5}
          name={`notificationsByLanguage[${index}].pushNotificationContent`}
          label={<TransField i18nKey="pushNotificationContent" />}
        />
      </Grid>
    </Grid>
  );
};
