import React from 'react';
import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransModal = createTrans({
  deleteNotificationTemplate: (
    <Trans
      i18nKey="modal.deleteNotificationTemplate"
      defaults="Delete notification template"
    />
  ),
  deleteNotificationTemplateDescription: (
    <Trans
      i18nKey="modal.deleteNotificationTemplateDescription"
      defaults="(1)[Are you sure you want to delete the selected notification template? You can't undo this];(2-inf)[
      Are you sure you want to delete the selected notification templates? You can't undo this]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
});
