import type { FC } from 'react';
import React from 'react';
import { Icon, Modal } from '@fleet/shared';
import { Button, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransBody } from 'i18n/trans/body';
import { TransButton } from 'i18n/trans/button';

interface DeletionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  selectedLanguageName: string | null;
}

export const LanguageDeletionConfirmationModal: FC<DeletionModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  selectedLanguageName,
}) => {
  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      title={
        <Typography variant="subtitle">
          <TransSubtitle i18nKey="deleteLanguage" />
        </Typography>
      }
      actionButton={
        <Button
          startIcon={<Icon name={'trash'} />}
          onClick={() => {
            onConfirm();
            onClose();
          }}
        >
          <TransButton i18nKey="delete" />
        </Button>
      }
    >
      <Typography variant="body1">
        <TransBody
          i18nKey="deleteLanguageModalBody"
          values={{ language: selectedLanguageName }}
        />
      </Typography>
    </Modal>
  );
};
