import type { FC } from 'react';
import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { NotificationByLanguageResponse } from 'dto/notificationTemplate';
import { TransBody } from 'i18n/trans/body';
import NotificationContentStatus from 'components/NotificationContentStatus';
import { hasNotificationContent } from 'helpers/notificationTemplate/hasNotificationContent';

interface SmsValidationSectionProps {
  notificationLanguage: NotificationByLanguageResponse;
}

export const SmsValidationSection: FC<SmsValidationSectionProps> = ({
  notificationLanguage,
}) => {
  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
        <Typography variant="subtitle">
          <TransSubtitle i18nKey="smsTemplate" />:
        </Typography>
        <NotificationContentStatus
          content={notificationLanguage.smsContent}
          contentErrorText={notificationLanguage.smsContentError}
          hasAnyContent={hasNotificationContent(notificationLanguage)}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ mb: 2, mt: 2 }}
      >
        <Typography variant="body1" fontWeight="bold">
          <TransBody i18nKey="content" />:
        </Typography>
        <Typography>{notificationLanguage.smsContent}</Typography>
      </Stack>
      <Divider />
    </>
  );
};
