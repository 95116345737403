import type { FC } from 'react';
import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { TransBody } from 'i18n/trans/body';
import { Icon } from '@fleet/shared';

interface WarningWithIconProps {}

export const WarningWithIcon: FC<WarningWithIconProps> = () => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Icon name="warning" color="warning" />
      <Box sx={{ marginLeft: 1 }}>
        <Typography variant="body1" fontWeight="bold" color="#E47A04">
          <TransBody i18nKey="emptyContent" />
        </Typography>
      </Box>
    </Stack>
  );
};
