import { classificationReducer } from 'features/classification/classificationReducer';
import { combineReducers } from '@reduxjs/toolkit';
import { loadingReducer } from 'features/loading/loadingReducer';
import { commonReducer } from 'features/common/commonReducer';
import { notificationTemplatesReducer } from 'features/notificationTemplate/notificationTemplateReducer';

export default combineReducers({
  loading: loadingReducer,
  classification: classificationReducer,
  common: commonReducer,
  notificationTemplate: notificationTemplatesReducer,
});
